<template>
    <button :disabled="!isReady" @click="() => login()" class="flex bg-gray-200 text-gray-900 rounded-full"
    :class="[width]">
        <span class="flex flex-row p-2 gap-2 w-full">
            <Icon name="mdi:google" class="h-6 w-6"/>
            <span class="flex flex-1 items-center justify-center">
                <span class="font-medium text-gray-700">Continue with Google</span>
            </span>
        </span>
    </button>
</template>

<script setup lang="ts">
import {
    useTokenClient,
    type AuthCodeFlowSuccessResponse,
    type AuthCodeFlowErrorResponse,
} from "vue3-google-signin";

const emit = defineEmits(['onGoogleSignInSuccess'])
const {width} = defineProps(['width'])

const handleOnSuccess = (response: AuthCodeFlowSuccessResponse) => {
  console.log(response)
    const credential = response["access_token"];
    if (credential) {
        emit('onGoogleSignInSuccess', {credential});
    }
};

const handleOnError = (errorResponse: AuthCodeFlowErrorResponse) => {
    console.log("Error: ", errorResponse);
};

const {isReady, login} = useTokenClient({
    onSuccess: handleOnSuccess,
    onError: handleOnError,
    // other options
});
</script>