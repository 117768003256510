<template>
    <div v-if="loadingPreDataState"
         class="relative p-6 flex items-center justify-center">
        <LoadingCircle />
    </div>
    <div v-else class="flex flex-grow items-center justify-center py-12 sm:px-6 lg:px-8 xsm:pt-5 md:py-16">
      <LoadingCircle v-if="loadingSocialLoginSuccess" class="flex items-center justify-center"/>
      <div v-else class="w-full">
        <SocialLoginSignupView
            :project-module="ProjectModule.PARENT_PORTAL"
            :company-details="getCompanyDetailsLocal"
            :session-ids="[]"
            :disable-registration="true"
            :also-save-as-student="false"
            :show-signup-link="false"
            :require-address="false"
            :continue-mode="ContinueMode.Login"
            :on-signup-press="()=>{}"
            @login-or-sign-up-success="onSocialLoginSuccess"
        />
      </div>
<!--        <AuthLoginPageEmailView v-if="currentAuthPageState==AuthPageState.EnterEmail" key="SignInPageMobileView" @go-to-password-view="goToPasswordView" @go-to-sign-up-view="changePageState(AuthPageState.SignUp)"/>-->
<!--        <AuthLoginPageEnterPasswordView v-else-if="currentAuthPageState==AuthPageState.EnterPassword" key="SignInPageEnterPasswordView" :email="email" @go-to-enter-email-state="changePageState(AuthPageState.EnterEmail)" @go-to-match-otp-state="changePageState(AuthPageState.MatchOTP)" @go-to-reset-password-state="changePageState(AuthPageState.ResetPassword)"/>-->
<!--        <AuthLoginPageMatchOtpView v-else-if="currentAuthPageState==AuthPageState.MatchOTP" key="SignInPageMatchOtpView" :email="email" @go-to-enter-email-state="changePageState(AuthPageState.EnterEmail)" @go-to-match-otp-state="changePageState(AuthPageState.MatchOTP)"/>-->
<!--        <AuthLoginPageResetPasswordView v-else-if="currentAuthPageState==AuthPageState.ResetPassword" key="SignInPageResetPasswordView" :email="email" @go-to-enter-email-state="changePageState(AuthPageState.EnterEmail)" @go-to-match-otp-state="changePageState(AuthPageState.MatchOTP)" @go-to-reset-password-success-state="changePageState(AuthPageState.ResetPasswordSuccess)"/>-->
<!--        <AuthLoginPageResetPasswordSuccessView v-else-if="currentAuthPageState==AuthPageState.ResetPasswordSuccess" key="SignInPageResetPasswordSuccessView" :email="email" @go-to-enter-email-state="changePageState(AuthPageState.EnterEmail)"/>-->
<!--        <AuthLoginPageSignUpView v-if="currentAuthPageState==AuthPageState.SignUp" key="SignUpPageMobileView" @go-to-email-view="changePageState(AuthPageState.EnterEmail)" @go-to-match-otp-state="goToMatchOtpViewFromSignUp"/>-->
    </div>
</template>

<script setup lang="ts">

import {ProjectModule} from "~/utils/enums/ProjectModule";

definePageMeta({
    middleware: ['auth'],
    layout: 'auth-page'
})

import {AuthPageState} from "~/utils/enums/AuthPageState";
import {useLoggedIdUserDataPreparationEvent, useLoginEvent} from "~/composables/useEventBus";
import {useRoute, useRouter} from "vue-router";
import {userStore} from "~/store/userStore";
import {companyStore} from "~/store/companyStore";
import AuthLoginPageEmailView from "~/components/auth-login/AuthLoginPageEmailView.vue";
import AuthLoginPageMatchOtpView from "~/components/auth-login/AuthLoginPageMatchOtpView.vue";
import AuthLoginPageResetPasswordView from "~/components/auth-login/AuthLoginPageResetPasswordView.vue";
import AuthLoginPageResetPasswordSuccessView from "~/components/auth-login/AuthLoginPageResetPasswordSuccessView.vue";
import AuthLoginPageSignUpView from "~/components/auth-login/AuthLoginPageSignUpView.vue";
import {ContinueMode} from "~/utils/enums/ContinueMode";
import {AppRoutes} from "~/utils/other/AppRoutes";
import type {User} from "~/models/user";
import {authenticationStore} from "~/store/authenticationStore";




const router = useRouter();
const useCompanyStore = companyStore();
const useUserStore = userStore();
const useAuthenticationStore = authenticationStore();
const {setupEverythingForLogin} = useAuthenticationStore;
const {getSidebarNavigations} = useUserStore;
const {getCompanyDetailsLocal, hasModuleOnCurrentCompany} = storeToRefs(useCompanyStore)
const {isLoggedInUserAnAdminOfThisCompany, isLoggedInUserAStaffOfThisCompany} = storeToRefs(useUserStore)
const loadingSocialLoginSuccess = ref<boolean>(false)
const loadingPreDataState = ref<boolean>(true);
const loadingState = ref<boolean>(false)
const email = ref<string>('')
const currentAuthPageState = ref<AuthPageState>(AuthPageState.EnterEmail)

const onSocialLoginSuccess = async (user:User)=>{
  loadingSocialLoginSuccess.value = true;
  await setupEverythingForLogin(user);
  const sidebarOptions = getSidebarNavigations(user, getCompanyDetailsLocal.value);
  console.log(sidebarOptions[0])
  await router.push({path: `${sidebarOptions[0].href}`, replace: true});
  // if(isLoggedInUserAnAdminOfThisCompany.value(getCompanyDetailsLocal.value.id) || isLoggedInUserAStaffOfThisCompany.value(getCompanyDetailsLocal.value.id)){
  //   const exceptionCompanyIds = [4282,4283,4291,4262,4291,4140]
  //   if(exceptionCompanyIds.includes(getCompanyDetailsLocal.value.id)){
  //     await router.push({path: AppRoutes.Transaction, replace: true});
  //   }else{
  //     //Go to dashboard page for admin and co-hosts
  //     await router.push({path: AppRoutes.Dashboard, replace: true});
  //   }
  // }
  // else{
  //   //Go to events page for participant
  //   await router.push({path: AppRoutes.Events, replace: true});
  // }
  loadingSocialLoginSuccess.value = false;
}

const goToPasswordView = (providedEmail:string) => {
    email.value = providedEmail;
    changePageState(AuthPageState.EnterPassword);
}

const goToResetPasswordView = (providedEmail:string) => {
    email.value = providedEmail;
    changePageState(AuthPageState.ResetPassword);
}

const changePageState = (newState:AuthPageState) => {
    currentAuthPageState.value = newState;
}

const goToMatchOtpViewFromSignUp = (providedEmail:string) => {
    email.value = providedEmail;
    changePageState(AuthPageState.MatchOTP);
}

function setUpEventListener() {
    useGlobalEventLister(useLoginEvent(), function (data: any) {
        loadingState.value = data?.loading == true
    })
    useGlobalEventLister(useLoggedIdUserDataPreparationEvent(), function (data: any) {
        loadingState.value = data?.loading == true
    })
}

onMounted(async () => {
    loadingPreDataState.value = true
    try {
        setUpEventListener();
        loadingPreDataState.value = false;
    } catch (e) {
        console.log(e);
        loadingPreDataState.value = false;
    }
})
</script>

<style scoped>

</style>