<template>
  <div v-if="loadingStudentInformation" class="w-full flex items-center justify-center">
    <LoadingCircle size="5"/>
  </div>
  <div v-else class="bg-white p-4 w-full rounded-md">
    <div class="w-full sm:flex sm:items-start md:items-center justify-center">
      <div class="w-full mt-3 text-center sm:mt-0 sm:text-left flex flex-col items-center justify-center">
        <div class="w-full">
          <div class="sm:flex sm:flex-row-reverse justify-center">
            <form class="space-y-2 py-2 w-full flex flex-col gap-6"
                  @submit.prevent="addUpdateUserInfo(user.id)"
            >
              <div class="flex flex-col">
                <div class="flex flex-col space-y-2 mt-4 text-sm placeholder:text-xs placeholder:font-thin">
                  <div class="flex flex-col items-start">
                    <label for="firstname"
                                               class="block text-sm font-light leading-2 text-gray-500 mb-0.5">{{('First Name')}}<span
                          class="text-red-500">*</span></label>
                    <input type="text" name="firstname" id="firstname" v-model="firstname"
                           :class="errorMap['First Name']?'ring-red-600':''"
                           class="block w-full rounded-md border-0 py-1.5  text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-color sm:text-sm sm:leading-6 placeholder:text-sm placeholder:font-thin disabled:bg-gray-200 disabled:cursor-not-allowed"
                                               :placeholder="('Enter first name')"/>
                  </div>

                  <div class="flex flex-col items-start">
                    <label for="lastname"
                                               class="block text-sm font-light leading-2 text-gray-500 mb-0.5">{{('Last Name')}}
                      <span
                          class="text-red-500">*</span></label>
                    <input type="text" name="lastname" id="lastname" v-model="lastName"
                           :class="errorMap['Last Name']?'ring-red-600':''"
                           class="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-color sm:text-sm sm:leading-6 placeholder:text-sm placeholder:font-thin disabled:bg-gray-200 disabled:cursor-not-allowed"
                                               :placeholder="('Enter last name')"/>
                  </div>

                  <div class="flex flex-col items-start">
                    <label for="email"
                                               class="block text-sm font-light leading-2 text-gray-500 mb-0.5">{{ ('Email') }}<span
                        class="text-red-500">*</span></label>
                    <input type="email" name="email" id="email" v-model="email"
                           disabled
                           :class="errorMap['Email']?'ring-red-600':''"
                           class="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-color sm:text-sm sm:leading-6 placeholder:text-sm placeholder:font-thin disabled:bg-gray-200 disabled:cursor-not-allowed"
                                               :placeholder="('Enter email')"/>
                  </div>

                  <div class="flex flex-col items-start">
                    <label for="phone"
                                               class="block text-sm font-light leading-2 text-gray-500 mb-0.5">{{ ('Phone') }}<span
                        class="text-red-500">*</span></label>
                    <div class="mt-2 flex rounded-md shadow-sm w-full">
                      <span
                          class="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm font-thin">+1</span>
                      <input type="tel" name="phone" id="phone" v-model="phone"
                             :class="errorMap['Phone']?'ring-red-600':''"
                             v-maska
                                                   data-maska="###-###-####" :placeholder="('Enter phone number')"
                             class="border-0 rounded-r w-full text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-color sm:text-sm sm:leading-6 placeholder:text-sm placeholder:font-thin disabled:bg-gray-200 disabled:cursor-not-allowed"/>
                    </div>

                  </div>
                </div>

                <div class="flex flex-col space-y-2 mt-4 text-sm placeholder:text-xs placeholder:font-thin">
                  <div class="flex flex-col items-start">
                    <label for="addressline1"
                                               class="block text-sm font-light leading-2 text-gray-500 mb-0.5">{{ ('Address') }}<span
                        class="text-red-500">*</span>
                    </label>
                    <input type="text" name="addressline1" id="addressline1" v-model="address1"
                           @input="changeData('Address one line 1')"
                           class="block w-full rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-color sm:text-sm sm:leading-6 placeholder:text-sm placeholder:font-thin"
                                               :placeholder="('Address Line 1(Optional)')"/>

<!--                    :class="errorMap['Address one line 1']?'ring-red-600':''"-->

                    <input type="text" name="addressline2" id="addressline2" v-model="address2"
                           class="block w-full rounded-md border-0 py-1.5 mt-2 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-color sm:text-sm sm:leading-6 placeholder:text-sm placeholder:font-thin"
                                               :placeholder="('Address Line 2(Optional)')"/>

                    <input type="text" name="city" id="city" v-model="city"
                           @input="changeData('City')" :class="errorMap['City']?'ring-red-600':''"
                           class="block w-full rounded-md border-0 py-1.5 mt-2 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-color sm:text-sm sm:leading-6 placeholder:text-sm placeholder:font-thin"
                                               :placeholder="('City')"/>

                    <div class="flex flex-col md:flex-row gap-2 mt-2 items-start md:items-center justify-center w-full">
                      <FormDropDown title="State" class="flex-grow w-full" :items="states"
                                    @on-change="updateState" :has-error="errorMap['State']"
                                    :selected-item="state"
                                    :key="state"/>
                      <input type="text" name="zip" id="zip" v-model="zip"
                             @input="changeData('Zip')" :class="errorMap['Zip']?'ring-red-600':''"
                             v-maska
                             data-maska="#####"
                             class="block w-full flex-grow rounded-md border-0 py-1.5 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-color sm:text-sm sm:leading-6 placeholder:text-sm placeholder:font-thin"
                                                   :placeholder="('Zip')"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex w-full items-center justify-center">
                <div class="md:w-1/2 flex flex-row gap-2 ">
                  <div @click="onBackPress"
                       class="cursor-pointer flex items-center justify-center py-2 bg-gray-200 rounded-md w-10"
                  >
                    <Icon class="text-gray-700 h-7 w-7 cursor-pointer"
                          name="ic:twotone-keyboard-arrow-left"/>
                  </div>
                  <button type="submit"
                          class="flex-1 rounded-md bg-brand-color w-full md:w-40 px-4 py-2 cursor-pointer text-white relative inline-flex text-center justify-center items-center content-center  text-sm font-semibold hover:scale-95 transition duration-400">
                    <LoadingCircle size="5" v-if="loading"/>
                    <div>
                      <span class="py-4 text-[13px] ">{{ ('Continue') }}</span>
                      <Icon name="ic:round-keyboard-arrow-right"/>
                    </div>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {countries, states} from "~/utils/other/Essentials";
import {useGlobalEvent, useNotificationEvent} from "~/composables/useEventBus";
import type {NotificationDetails} from "~/models/notificationDetails";
import {NotificationType} from "~/utils/enums/NotificationType";
import moment from "moment/moment";
import type {SocialLoginSignupStudentInfoProps} from "~/models/props/socialLoginSignupStudentInfoProps";
import {studentStore} from "~/store/studentStore";
import {userStore} from "~/store/userStore";
import type {Student} from "~/models/student";
import {onMounted} from "vue";
import {sessionStore} from "~/store/sessionStore";
import type {AddStudentIntoSessionRb} from "~/models/request/addStudentIntoSessionRb";
import {Mask, vMaska} from "maska";
import {getPhoneWithCountryCode, isValidEmail, trimmedPhoneWithoutCountryCode} from "~/utils/helpers";
import type {User, UserAddress} from "~/models/user";
import type {AddUpdateUserRb} from "~/models/request/addUpdateUserRb";
import {storeToRefs} from "pinia";
import {companyStore} from "~/store/companyStore";
import type {AddUpdateUser} from "~/models/request/addCoHostRb";

const useUserStore = userStore();
const useSessionStore = sessionStore();
const useStudentStore = studentStore();
const useCompanyStore = companyStore();
const {addUpdateUserAddress, createUpdateUser} = useUserStore;
const {getStudentDetailsByEmailServer} = useStudentStore;
const {addStudentIntoSession} = useSessionStore;
const {getCompanyDetailsLocal} = storeToRefs(useCompanyStore);

const loadingStudentInformation = ref<boolean>(true);
const profilePicture = ref<string>()
const firstname = ref<string>()
const lastName = ref<string>()
const middleName = ref<string>()
const gender = ref<string>()
const dateOfBirth = ref<string>()
const email = ref<string>()
const phone = ref<string>()
const address1 = ref<string>()
const address2 = ref<string>()
const city = ref<string>()
const state = ref<string>()
const zip = ref<string>()
const country = ref<string>()
const educationLevel = ref<string>()
const occupation = ref<string>()
const organization = ref<string>()
const department = ref<string>()
const website = ref<string>()
const programOfStudy = ref<string>()
const loading = ref<boolean>()
const profilePictureLoading = ref<boolean>()
const hasUniversity = ref<boolean>()
const errorMap = ref<any>({});

const {companyId, sessionIds, user, alsoSaveAsStudent} = defineProps<SocialLoginSignupStudentInfoProps>();

const emit = defineEmits<{
  (event: 'userInfoUpdateSuccess', user: User): any,
  (event: 'onBackPress'): any,
}>();

const onBackPress = () => {
  emit("onBackPress");
}

function updateState(item: any) {
  state.value = item['name'];
  changeData("State")

}


async function addUpdateUserInfo(id: any) {
  let error = hasError();
  errorMap.value = error;
  if (Object.keys(error).length) {
    useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
      type: NotificationType.ERROR,
      title: 'Please fill out all the required field',
      message: "",
    });

  } else {
    loading.value = true;

    const addUser: AddUpdateUser = <AddUpdateUser>{
      id: id,
      FirstName: firstname.value,
      LastName: lastName.value,
      Email: email.value,
      Cellphone: getPhoneWithCountryCode(phone.value ?? '') ?? '',
      company_id: companyId,
      roleIds: [20],
    }

    let {data} = await createUpdateUser(addUser, true);

    const response = await addUpdateUserAddress({
      "user_id": id,
      "line1": `${address1.value ?? ''}`,
      "line2": `${address2.value ?? ''}`,
      "city": `${city.value ?? ''}`,
      "state": `${state.value ?? ''}`,
      "zip": `${zip.value ?? ''}`,
    })

    if (response) {
      user.UserAddress = response as UserAddress;
      await addUpdateStudent(user.Email);
      emit('userInfoUpdateSuccess', user);
    } else {
      loading.value = false;
    }
  }
}

async function addUpdateStudent(email: any) {
  if (alsoSaveAsStudent) {
    try {
      const student = await getStudentDetailsByEmailServer(email, companyId);
      const studentId = student?.id;

      let studentRB = {
        "student": {
          "FirstName": `${firstname.value}`,
          "Id": studentId ?? null,
          "LastName": `${lastName.value}`,
          "MiddleName": `${middleName.value ?? ''}`,
          "Email": `${email}`,
          "Phone": `${getPhoneWithCountryCode(phone.value?.toString()??'')}`,
          "Gender": `${gender.value}`,
          "IsKinderGartenStudent": 0,
          "ProfilePicture": `${profilePicture.value ?? ''}`,
          "ProfilePictureThumb": `${profilePicture.value ?? ''}`,
          "StudentId": 0,
          "Dob": dateOfBirth && moment(dateOfBirth.value, "YYYY-MM-DD").format("YYYYMMDD"),

          "Allergies": null,
          "Grade": -1,
          "CompanyId": companyId,
          "hasLogin": true,
          "sendEmail": true,
          "StudentAdditionalInformation": {
            "AddressLine1": `${address1.value ?? ''}`,
            "AddressLine2": `${address2.value ?? ''}`,
            "City": `${city.value ?? ''}`,
            "State": `${state.value ?? ''}`,
            "Zip": `${zip.value ?? ''}`,
            "Country": `${country.value ?? ''}`,
            "Education": `${educationLevel.value ?? ''}`,
            "Occupation": `${occupation.value ?? ''}`,
            "Workplace": `${organization.value ?? ''}`,
            "Website": `${website.value ?? ''}`,
            "Department": `${department.value ?? ''}`,
            "Program": `${programOfStudy.value ?? ''}`,
          }
        },
      };

      useStudentStore.addStudentServerWithAdditionalInfo(studentRB).then((response) => {
        const {Result} = response;
        const student = Result as Student;
        if (student?.id) {
          addStudentIntoSession(<AddStudentIntoSessionRb>{
            studentId: student.id,
            sessionIds: sessionIds ?? [],
            Status: 1,
          }, true);
        }
      }).catch((error) => {
        console.log(error);
      })
    } catch (e) {
      console.log(e);
    }
  }
}


function hasError() {

  let error = {} as any;

  if (!firstname.value)
    error['First Name'] = true;


  if (!lastName.value)
    error['Last Name'] = true;

  if ((!email.value) || (!isValidEmail(email.value ?? '')))
    error['Email'] = true;

  if (!phone.value || (trimmedPhoneWithoutCountryCode(phone.value ?? '').length != 10))
    error['Phone'] = true;

  // if ((address1.value?.trim().length ?? 0) < 1)
  //   error['Address one line 1'] = true;

  if ((city.value?.trim().length ?? 0) < 1)
    error['City'] = true;

  if (!state.value)
    error['State'] = true;

  if (zip.value?.length != 5)
    error['Zip'] = true;


  if (error) {
    return error;
  } else {
    return {};

  }
}

function changeData(key: String) {
  if (key == "First Name" && firstname.value) {
    errorMap.value[`${key}`] = false;

  } else if (key == "Last Name" && lastName.value) {
    errorMap.value[`${key}`] = false;

  } else if (key == "Gender" && gender.value) {
    errorMap.value[`${key}`] = false;

  } else if (key == "Date Of Birth" && dateOfBirth.value) {
    errorMap.value[`${key}`] = false;

  } else if (key == "Email" && email.value) {
    errorMap.value[`${key}`] = false;

  } else if (key == "Phone" && phone.value) {
    errorMap.value[`${key}`] = false;

  }
  // else if (key == "Address one line 1" && address1.value) {
  //   errorMap.value[`${key}`] = false;
  // }

  else if (key == "City" && city.value) {
    errorMap.value[`${key}`] = false;

  } else if (key == "State" && state.value) {
    errorMap.value[`${key}`] = false;

  } else if (key == "Zip" && zip.value) {
    errorMap.value[`${key}`] = false;

  }
}

const populateData = (user: User) => {
  if (user.UserAddress?.id > 0) {
    emit('userInfoUpdateSuccess', user);
  } else {
    firstname.value = user['FirstName'];
    lastName.value = user['LastName'];
    gender.value = user['Gender'] ?? '';
    email.value = user['Email'] ?? '';
    profilePicture.value = user['ProfilePicture'] ?? '';
    phone.value = `${user['cellphone'] ?? ''}`.replace("+1", "");
  }
}

onMounted(async () => {
  loadingStudentInformation.value = true;
  try {
    populateData(user as User);
  } catch (e) {
    console.log(e)
  }
  setTimeout(() => {
    loadingStudentInformation.value = false;
  }, 1000)
})


</script>

<style scoped>

</style>
